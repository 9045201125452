<template>
  <v-navigation-drawer v-model="showDrawer" absolute temporary width="310">
    <v-toolbar
      :dark="appThemeConfig.dark"
      prominent
      :height="isApp ? 250 + systemBarHeight : 250"
      :style="isApp ? `padding: ${systemBarHeight}px 0` : ''"
      :src="require('@/assets/background/colorful/drawer-bg.jpg')"
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-export</v-icon>
      </v-btn>
      <v-toolbar-title
        :style="`position: absolute; bottom: ${systemBarHeight}px`"
      >
        <div style="flex-direction: column" class="d-flex">
          <v-avatar :elevation="3" size="56">
            <img :src="userAvatar" alt="John" />
          </v-avatar>
          <div class="mt-2">{{ userFriendName }}</div>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <div class="audio-player rounded-lg">
      <div id="aplayer" class="vue-plarer rounded-lg"></div>
      <v-btn-toggle class="btn-group rounded-lg" v-model="audioChose">
        <v-btn @click="playAudio(0)">海浪</v-btn>
        <v-btn @click="playAudio(1)">夏日</v-btn>
        <v-btn @click="playAudio(2)">流水</v-btn>
      </v-btn-toggle>
    </div>

    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>账号信息</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>偏好设置</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-title>客服中心</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title>关于我们</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item v-if="appMode === 'dev' || appMode === 'LOCAL'"
        ><v-switch
          :input-value="showConsole"
          inset
          label="调试控制台"
          @change="switchVconsole"
        ></v-switch>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import "APlayer/dist/APlayer.min.css";
import APlayer from "APlayer";
import { isEmpty } from "@/utils/common";
import { avatrTool, userInfo } from "@/mixin/mixin";
import { debug } from "@/mixin/debug";

export default {
  name: "appDrawer",
  mixins: [userInfo, avatrTool, debug],
  props: {},
  components: {},
  watch: {
    appStatus() {
      if (this.appStatus === 0) {
        this.stopAudio();
      }
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
      appStatus: "appStatus",
    }),
    showDrawer: {
      get() {
        return this.$store.getters["showDrawer"];
      },
      set(showDrawer) {
        return this.$store.dispatch("setShowDrawer", showDrawer);
      },
    },
  },
  data: () => ({
    appMode: null,
    group: null,
    audioChose: null,
    aplayer: null,
  }),
  methods: {
    playAudio(index) {
      if (!isEmpty(index) && 3 > index >= 0) {
        this.aplayer.list.switch(index);
      }
      this.aplayer.play();
    },
    stopAudio() {
      this.aplayer.pause();
    },
  },
  mounted() {
    this.appMode = process.env["VUE_APP_MODE"];
    this.aplayer = new APlayer({
      container: document.getElementById("aplayer"),
      mini: true,
      autoplay: false,
      theme: "#ffaf64",
      loop: "one",
      order: "list",
      preload: "metadata",
      volume: 0.7,
      mutex: true,
      audio: [
        {
          name: "wave",
          url: "./static/media/sounds/s-wave.mp3",
          cover: require("@/assets/background/colorful/material2.png"),
        },
        {
          name: "summer",
          url: "./static/media/sounds/s-summer.mp3",
          cover: require("@/assets/background/colorful/material2.png"),
        },
        {
          name: "water",
          url: "./static/media/sounds/s-water.mp3",
          cover: require("@/assets/background/colorful/material2.png"),
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.audio-player {
  margin: 1.5rem auto 1rem auto;
  width: 18rem;
  height: 5rem;
  background-color: #d1f1a9;
  display: flex;
  align-items: center;
  .vue-plarer {
    margin: 0.8rem;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-group {
    margin: 0 0 0 0.5rem;
  }
}
::v-deep .aplayer .aplayer-pic .aplayer-pause {
  bottom: 0.6rem;
  right: 0.6rem;
}
</style>
