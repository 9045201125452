import Vue from "vue";

export const debug = {
  data() {
    return {
      // showConsole: true,
    };
  },
  computed: {
    showConsole() {
      return Vue.prototype.$VConsole;
    },
  },
  methods: {
    //控制显示vconsole
    switchVconsole() {
      if (Vue.prototype.$VConsole) {
        Vue.prototype.$VConsole.destroy();
        Vue.prototype.$VConsole = null;
        localStorage.removeItem("debug");
        console.log("销毁VConsole");
      } else {
        const VConsole = require("vconsole");
        Vue.prototype.$VConsole = new VConsole();
        localStorage.setItem("debug", "1");
        console.log("启用VConsole");
      }
    },
  },
  mounted() {},
};
